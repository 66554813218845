/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import axios from 'axios'
import Cookies from 'js-cookie'
import React from 'react'
import semver from 'semver'
import UAParser from 'ua-parser-js'

import InformationModal from 'components/modals/InformationModal'
import utils from 'components/react_components/utils'

export function browserVersionMatchesRequiredVersion(browserVersion, requiredVersion) {
    return semver.satisfies(semver.coerce(browserVersion), requiredVersion)
}

export default class BrowserCompatibilityTest extends React.Component<
    {},
    { browserCompatibilityConfig: any; testHasRun: boolean }
> {
    constructor(props) {
        super(props)
        this.state = {
            browserCompatibilityConfig: null,
            testHasRun: null
        }
    }

    componentDidMount = async () => {
        try {
            const configResponse = await axios.get(window.Urls.configEntry('academy_app.BrowserCompatibilityKey'))
            if (configResponse.data.config) {
                this.setState({ browserCompatibilityConfig: JSON.parse(configResponse.data.config) })
            }
        } catch (e) {
            utils.defaultAjaxErrorHandler(e)()
        }
        this.setState({ testHasRun: Cookies.get('browserCompatibilityTestHasRun') })
    }

    onHide = () => {
        Cookies.set('browserCompatibilityTestHasRun', true, { path: window.COOKIE_PATH })
        this.setState({ testHasRun: Cookies.get('browserCompatibilityTestHasRun') })
    }

    testBrowser = () => {
        if (!this.state.browserCompatibilityConfig) {
            return null
        }

        const parser = new UAParser()
        const browser = parser.getBrowser()
        const browserName = browser.name
        const requiredBrowserVersion = this.state.browserCompatibilityConfig[browserName]
        const errorMessage = gettext(
            'We recommend using the newest version of Chrome, Firefox, Internet Explorer or Edge.\n\nNote for Safari users: There are known issues with Safari version 12.1.x. You may either try the Safari Technology Preview or another compatible browser.\n\nYou may proceed, but you may encounter unexpected issues.'
        )
        if (!requiredBrowserVersion) {
            return errorMessage
        }
        if (!browserVersionMatchesRequiredVersion(browser.version, requiredBrowserVersion)) {
            return errorMessage
        }
        return null
    }

    render() {
        const errorMessage = this.testBrowser()
        if (this.state.testHasRun || !errorMessage) {
            return null
        }

        return (
            <InformationModal
                title={gettext('Your browser is not supported')}
                contentText={errorMessage}
                onClose={this.onHide}
            />
        )
    }
}
