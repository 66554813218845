/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-unresolved
import 'core-js/actual'
import 'vite/modulepreload-polyfill'

import cssHasPseudo from 'css-has-pseudo/browser'

import 'utils/axios_defaults'
import 'sections/announcements/announcements-section'
import 'sections/browser_compatibility/browser_compatibility_test-section'
import 'common'
import 'sections/cookie_disclaimer/cookie_disclaimer-section'
import 'sections/footer/footer-section'
import 'sections/host_mismatch_modal/host_mismatch_modal-section'
import 'sections/navbar/navbar-section'
import 'sections/termsofuse_check/termsofuse_check-section'

cssHasPseudo(document)
